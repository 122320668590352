
.ExamEnd {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    .end-exam-img {
        width: 30%;
    }
    .hint {
        margin: 40px 0;
        font-size: 14px;
        color: #333;
    }
    .custom-btn {
        width: 180px;
        height: 36px;
    }
}
